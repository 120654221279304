import React from "react"

import "../../styles/index.scss"
import "../../styles/secondary_pages.scss"

import Layout from "../../components/layout"
import SEO from "../../components/seo"


import { Link, graphql } from "gatsby"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'


import BannerMobile from "../../images/editorial/mgmt-consulting-banner-mobile.jpg"
import Banner from "../../images/editorial/mgmt-consulting-banner.jpg"

import IconRevCycle from "../../images/svg/icon-rev-cycle.svg"
import IconContracting from "../../images/svg/icon-contracting-credentialing.svg"
import IconConsulting from "../../images/svg/icon-mgmt-services-consulting.svg"
import IconPracticeMgmt from "../../images/svg/icon-practice-mgmt.svg"

import ImgMgmtConsulting from "../../images/svg/mgmt-consulting.svg"
import ImgGrowthCompass from "../../images/svg/growth-compass.svg"
import ImgStreamlinedSolutions from "../../images/svg/streamlined-solutions.svg"
import ImgBuildingBlocks from "../../images/svg/building-blocks.svg"
import ImgSuitcase from "../../images/svg/doctor-suitcase.svg"

const RevCycle = () => (

  <React.Fragment>
    <SEO title="Management Services and Consulting" description="Leverage our expertise to grow your practice. For more than 35 years, MSM has been partnering with practices to maximize profits and drive down costs. " />

    <Layout>

      <section id="sct-uvp" className="container ctr-headline secondary-headline">
        <div className="frame-image">
          <article>

            <h2>Leverage our expertise to<br />
              <span>grow your practice.</span></h2>
            <p>Struggling to manage your practice? Leave the details to us. </p>

            <button className="btn-primary">
              <a href="#learnmore">Learn More</a>
            </button>
          </article>

          <picture>
            <source srcSet={BannerMobile} media="(max-width:600px)" />
            <source srcSet={Banner} />
            <img src={Banner} alt="" className="lazyload" />
          </picture>


        </div>
      </section>

      <section className="container ctr-potential ctr-newframework" id="sct-newframework">
        <div className="animated ">
          <h2>Build your physician enterprise, without the hassle.</h2>
          <p>Juggling the requirements of your medical practice can constantly tug your attention away from patient care—and ultimately inhibit the growth of your bottom line. You may want to remain independent, but lack the resources to manage back-end logistics from day to day. </p>
          <p>
            What if there were a low-lift, hassle-free way to expand your physician enterprise?
     </p>

          <p>MSM was founded for that express purpose. We absorb your administrative challenges seamlessly—and handle them just like we’re part of your team. We know healthcare inside and out, so we understand how to nip inefficiencies in the bud and craft a practice management approach that dovetails perfectly with your operational needs and revenue goals. </p>


        </div>


        <img src={ImgMgmtConsulting} />

      </section>
      <a name="learnmore" />
      <section className="container ctrinfoGraphicContainer" >
        <h3></h3>
        <div className="content">
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                A growth compass
      </h4>
              <p>Navigating the waters of physician enterprise is difficult. We guide you through complex challenges you face to find the right solution.</p>
            </div>
            <div className="svg">
              <img src={ImgGrowthCompass} />
            </div>
          </article>

          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                Streamlined solutions
      </h4>
              <p>It’s difficult to find one reliable vendor, let alone a team of vendors who can manage it all. Our extensive industry experience means you only need one partner—because everything you need is under one roof. </p>
            </div>
            <div className="svg">
              <img src={ImgStreamlinedSolutions} />
            </div>

          </article>
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                The building blocks of growth
      </h4>
              <p>Though you’re an expert at what you do, you need reliable partners to be successful in this market. We fill in the areas where you need guidance—from business development to HR administration. We incubate your growth and teach you how to maximize your potential. </p>
            </div>
            <div className="svg">
              <img src={ImgBuildingBlocks} />
            </div>

          </article>

        </div>

      </section>

      <section className="container list-container" style={{ backgroundColor: "#f6f7fa" }}>
        <h3>When it comes to practice management, we’ve seen and done it all:  </h3>


        <ul className="ctr-list-component">
          <li><FontAwesomeIcon icon={faCaretRight} />Auditing and Compliance</li>
          <li><FontAwesomeIcon icon={faCaretRight} />Business Development and Marketing</li>
          <li><FontAwesomeIcon icon={faCaretRight} />Claims Adjudication</li>
          <li><FontAwesomeIcon icon={faCaretRight} />Physician Compensation Plans and Strategies</li>
          <li><FontAwesomeIcon icon={faCaretRight} />Data Analytics</li>
          <li><FontAwesomeIcon icon={faCaretRight} />EHR Workflow and Optimization</li>
          <li><FontAwesomeIcon icon={faCaretRight} />Financial Services</li>
          <li><FontAwesomeIcon icon={faCaretRight} />Hospital Privileging</li>
          <li><FontAwesomeIcon icon={faCaretRight} />HR Administration</li>
          <li><FontAwesomeIcon icon={faCaretRight} />MACRA / MIPS and other P4P Programs</li>
          <li><FontAwesomeIcon icon={faCaretRight} />Pay for Performance (HCC)</li>
          <li><FontAwesomeIcon icon={faCaretRight} />Payor Contracting and Credentialing</li>
          <li><FontAwesomeIcon icon={faCaretRight} />New Medical Practice Startup / Integration</li>
          <li><FontAwesomeIcon icon={faCaretRight} />Mid-Level Provider Recruitment</li>
          <li><FontAwesomeIcon icon={faCaretRight} />Technology Services</li>
          <li><FontAwesomeIcon icon={faCaretRight} />Utilization Management</li>
        </ul>

        <div className="svg">
          <img src={ImgSuitcase} />
        </div>
      </section>

      <section className="container ctr-cta" style={{ backgroundColor: "#16cac3", color: "#fff" }}>
        <h3>Jump-start your physician enterprise’s growth with MSM’s unequivocal expertise.</h3>
        <p> Explore our extensive healthcare management services today.</p>
        <button className="btn-primary btn-inverse" style={{ backgroundColor: "#fff", color: "#16cac3" }}>
          <Link to="/contact">Learn More</Link>
        </button>
      </section>

    </Layout>




  </React.Fragment >

)

export default RevCycle;